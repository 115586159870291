.listing-item {
  display: grid;
  gap: $spacing-8;
  background-color: $color-neutral-75;
  color: $color-neutral-900;
  padding: $spacing-8;
  border-radius: $border-radius;
  border: 1px solid $color-neutral-100;
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-4;
  }
  &__title {
    font-size: 14px;
    color: $color-neutral-800;
    font-weight: normal;
  }
  &__cell {
    flex-grow: 1;
    display: grid;
    text-transform: capitalize;
    color: $color-brand-500;
    font-weight: bold;
    background-color: $color-neutral-50;
    padding: $spacing-8 $spacing-12;
    border-radius: $border-radius;
    border: 1px solid $color-neutral-100;
    &--full {
      grid-column: 1/-1;
      font-size: 12px;
      padding-left: $spacing-4;
    }
  }
}

a {
  color: $color-brand-500;
  font-weight: $font-weight-medium;
}

.block-link {
  position: relative;
  display: block;
  text-decoration: none;
  border-radius: $border-radius;
  z-index: 1;
  overflow: hidden;
  transition: all 100ms ease-out;
  &::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(
      90deg,
      transparentize($color-brand-500, 0.9) 0%,
      transparentize($color-brand-500, 0.5) 100%
    );
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 100ms ease-out;
  }
  &:hover {
    &::before {
      opacity: 0.8;
    }
  }
}

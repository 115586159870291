.button {
  background-color: $color-brand-500;
  width: max-content;
  min-width:  $spacing-48;
  height: $spacing-48;
  display: flex;
  gap: $spacing-8;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: $spacing-16;
  font-weight: $font-weight-medium;
  color: $color-neutral-900;
  color: $color-neutral-50;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0 $spacing-16;
  border-radius: $border-radius;
  border: 1px solid transparent;
  border-bottom: 2px solid $color-brand-600;
  transition: all 100ms ease-out;
  user-select: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.1;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: -1px;
      border-radius: 2px;
      outline: 2px solid $color-brand-500;
      outline-offset: 4px;
    }
  }
  &:hover {
    background-color: $color-brand-400;
    border-bottom-color: $color-brand-500;
  }
  &--second {
    background-color: $color-neutral-50;
    border-color: $color-brand-400;
    color: $color-brand-500;
    &:hover {
      background-color: $color-brand-100;
      border-color: $color-brand-400;
    }
  }
  &--third {
    background-color: $color-accent-200;
    // border: 1px solid $color-neutral-900;
    color: $color-neutral-50;
  }
  &--danger {
    background-color: $color-danger-500;
    color: $color-neutral-50;
  }
  &--stretch {
    width: 100%;
  }
  &--inline {
    display: inline-flex;
    margin: 0 $spacing-4;
  }
}

.error {
  background-color: hsla(0, 95%, 24%, 0.5);
  border-radius: $border-radius;
  font-size: $font-size-12;
  padding: $spacing-16;
  margin-top: $spacing-12;
  &::before {
    content: "Error: ";
    font-weight: $font-weight-bold;
  }
}

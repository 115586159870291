.land-info-view {
  display: grid;
  gap: $spacing-8;
  background-color: $color-neutral-50;
  padding: $spacing-8 $spacing-12;
  border-radius: $border-radius;
  user-select: none;
  &__status {
    display: flex;
    align-items: center;
    gap: $spacing-8;
    font-weight: bold;
    text-transform: capitalize;
    color: $color-accent-200;
    cursor: pointer;
    &--fade {
      opacity: 0.66;
      color: $color-neutral-900;
      cursor: default;
    }
  }
  &__content {
    display: grid;
    gap: $spacing-4;
  }
}

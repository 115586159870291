.table {
  // border: 1px solid red;
  border-collapse: collapse;
  width: 100%;
  &__head {
    background-color: $color-accent-100;
    .table__cell {
      text-align: left;
    }
  }
  // &__body {
  //   // background-color: beige;
  // }
  &__row {
    border-bottom: 1px solid $color-neutral-200;
    &:nth-child(even) {
      background-color: $color-neutral-75;
    }
    // color: orange;
  }
  &__cell {
    padding: $spacing-12;
    min-width: 150px;
  }
}

.welcome {
  display: grid;
  margin: 0 auto;
  height: 100vh;
  align-items: center;
  align-content: center;
  &__content {
    width: 300px;
    display: grid;
    gap: $spacing-24;
    background-color: $color-neutral-50;
    padding: $spacing-16;
    border: 1px solid $color-neutral-100;
    border-radius: $border-radius;
    box-shadow: $drop-shadow;
    button {
      width: 100%;
      justify-content: center;
    }
  }
  &__group {
    display: grid;
    gap: $spacing-8;
  }
}

.wizard-progress {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-12;
  align-items: center;
  padding-bottom: $spacing-16;
  border-bottom: 1px solid $color-neutral-100;
  margin-bottom: $spacing-16;
  &__step {
    flex-grow: 1;
    display: grid;
    grid-template-columns: $spacing-48 1fr;
    gap: $spacing-8;
    &--current {
      color: $color-neutral-900;
      .wizard-progress__step-underline {
        background-color: $color-accent-200;
      }
      .wizard-progress__step-number {
        background-color: $color-accent-200;
        color: $color-neutral-900;
      }
      .wizard-progress__step-name {
        color: $color-neutral-900;
        font-weight: bold;
      }
    }
    &--done {
      color: $color-brand-500;
      .wizard-progress__step-underline {
        background-color: $color-brand-500;
      }
      .wizard-progress__step-number {
        background-color: $color-brand-500;
        color: $color-neutral-50;
      }
      .wizard-progress__step-name {
        color: $color-brand-500;
      }
    }
  }
  &__step-number {
    height: $spacing-32;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-neutral-75;
    border-radius: $border-radius 24px;
    grid-row: 1 / 3;
    font-weight: bold;
    color: $color-neutral-400;
  }
  &__step-name {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: $spacing-8;
    min-width: 128px;
    margin-right: $spacing-16;
    text-transform: capitalize;
    color: $color-neutral-400;
    font-size: $font-size-14;
    svg {
      flex-shrink: 0;
    }
  }
  &__step-underline {
    grid-column: span 2;
    height: 4px;
    background-color: $color-neutral-75;
    border-radius: 8px;
    align-self: flex-end;
    grid-column: 2;
  }
}

.land-section-item {
  display: grid;
  gap: $spacing-12;
  background-color: transparentize($color-neutral-50, 0.5);
  padding: $spacing-16 $spacing-12 $spacing-12;
  border: 1px solid $color-neutral-100;
  border-radius: $border-radius;
  // &__lands {
  //   display: grid;
  //   gap: $spacing-8;
  //   grid-template-columns: 1fr 1fr;
  // }
}

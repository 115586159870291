.stack-item {
  border-bottom: 1px solid $color-neutral-100;
  &__link {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    border-radius: $border-radius;
    transition: all 100ms ease-out;
    &:hover {
      background-color: $color-brand-500;
      color: $color-neutral-900;
    }
  }
  &__content {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $spacing-12;
    justify-content: left;
    align-items: center;
    font-size: $font-size-16;
    padding: $spacing-16 0;
  }
}

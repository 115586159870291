.wizard-controls {
  background-color: transparentize($color-neutral-50, 0.1);
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-16;
  padding: $spacing-16;
  border-top: 1px solid $color-neutral-200;
  z-index: 10;
  &__controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $spacing-12;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacing-8;
  font-size: $font-size-12;
  padding: $spacing-16;
  margin-top: auto;
}

.widget {
  background-color: $color-neutral-50;
  display: grid;
  align-items: start;
  grid-auto-rows: auto 1fr;
  border-radius: $border-radius;
  border: 1px solid $color-neutral-100;
  box-shadow: $drop-shadow;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding: $spacing-20 $spacing-20 $spacing-16;
    border-bottom: 1px solid $color-neutral-100;
    &--with-controls {
      padding: $spacing-12 $spacing-12 $spacing-12 $spacing-20;
    }
  }
  &__icon svg {
    width: $spacing-24;
    height: $spacing-24;
  }
  &__main {
    display: grid;
    gap: $spacing-16;
    padding: $spacing-16 $spacing-16 $spacing-20;
  }
}

@import "./styles/reset";
@import "./styles/variables";
@import "./styles/typography";
@import "./styles/layout";
@import "./styles/general";
@import "./styles/utils";
@import "./styles/link";

// layout
@import "./styles/components/header";
@import "./styles/components/footer";
@import "./styles/components/widget";
@import "./styles/components/stack";
@import "./styles/components/stack-item";

// navigation
@import "./styles/components/menu";

// ui
@import "./styles/components/button";
@import "./styles/components/toggle";
@import "./styles/components/dialog";
@import "./styles/components/form";
@import "./styles/components/status";
@import "./styles/components/tabs";
@import "./styles/components/popup-tip";
@import "./styles/components/modal";
@import "./styles/components/modal-controls";
@import "./styles/components/error";
@import "./styles/components/failed-getting-user";
@import "./styles/components/avatar";
@import "./styles/components/card";
@import "./styles/components/table";

// pages
@import "./styles/components/welcome";

// components
@import "./styles/components/profile-view";
@import "./styles/components/resource-list";
@import "./styles/components/land-section-list";
@import "./styles/components/land-section-item";
@import "./styles/components/land-card";
@import "./styles/components/land-list";
@import "./styles/components/land-item";
@import "./styles/components/land-info-view";
@import "./styles/components/business-item";
@import "./styles/components/enrollment-intro";
@import "./styles/components/enrollment-item";
@import "./styles/components/document-item";
@import "./styles/components/listing-item";
@import "./styles/components/weather";
@import "./styles/components/wizard-progress";
@import "./styles/components/wizard-controls";
@import "./styles/components/ordered-list";
@import "./styles/components/credits";
@import "./styles/components/listing-new";
@import "./styles/components/credits-portfolio";
@import "./styles/components/land-editor";
@import "./styles/components/land-map";

.logo {
  display: grid;
  width: auto;
  height: $spacing-48;
  justify-self: center;
  align-items: center;
}

.coming-soon {
  width: 100%;
  background-color: $color-neutral-100;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: $spacing-16;
  border-radius: $border-radius;
  text-transform: capitalize;
  font-weight: bold;
  color: $color-neutral-500;
}

.sequence {
  background-color: $color-brand-100;
  color: $color-brand-600;
  font-weight: bold;
  padding: $spacing-4 $spacing-12;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button {
  background-color: transparent;
  border: none;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: $border-radius;
  color: $color-brand-500;
  cursor: pointer;
  transition: all 100ms ease-out;
  &:hover {
    background-color: $color-brand-100;
  }
  &:active {
    background-color: $color-brand-500;
    color: $color-brand-100;
  }
}

.chem-fallow-years-group {
  background-color: $color-neutral-50;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: $spacing-16;
  height: 150px;
  border: 1px solid $color-neutral-200;
  border-radius: $border-radius;
  padding: $spacing-12 $spacing-16;
  overflow: auto;
}

.map-container {
  position: relative;
  min-height: 400px;
  height: 100%;
  border-radius: $border-radius;
  &__back {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1000;
  }
}

.map {
  background-color: $color-neutral-50;
  height: 512px;
  border-radius: $border-radius;
}

.viewport {
  position: relative;
  overflow: hidden;
}

.viewport canvas {
  width: 100% !important;
  height: 512px;
}

.land-card {
  display: none;
  padding: $spacing-16;
  background-color: transparentize($color-brand-500, 0.5);
  border-radius: $border-radius;
  font-weight: bold;
}

.point {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: opacity 100ms ease-out;
  &--visible {
    opacity: 1;
  }
  &__icon {
    transform: translate(-50%, -90%);
  }
}

.helpers {
  display: none;
}
.helper-1 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50%;
  right: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  pointer-events: none;
}
.helper-2 {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 50%;
  right: 0;
  background-color: rgba(30, 255, 0, 0.5);
  pointer-events: none;
}
.helper-3 {
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 50%;
  background-color: rgba(0, 17, 255, 0.5);
  pointer-events: none;
}
.helper-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  background-color: rgba(225, 0, 255, 0.5);
  pointer-events: none;
}

.svg-text {
  font-family: "Source Sans Pro", sans-serif;
  stroke: none;
  fill: $color-neutral-50;
  font-weight: bold;
}

.steps {
  display: grid;
  gap: $spacing-12;
}

.details {
  display: flex;
  gap: $spacing-12;
  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: $spacing-4;
    align-items: center;
  }
  &__icon {
    font-size: $spacing-16;
    color: $color-accent-200;
    line-height: 0.5;
  }
  &__name {
    display: flex;
    align-items: center;
    gap: $spacing-4;
    font-size: $spacing-16;
    font-weight: bold;
    text-transform: capitalize;
    color: $color-accent-200;
  }
  &__value {
    grid-column: 2 / -1;
    font-size: $spacing-24;
    font-weight: bold;
    line-height: 1;
  }
  &__unit {
    font-size: $spacing-16;
    color: $color-accent-200;
  }
}

.land-select {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-16;
  &__section {
    background-color: $color-neutral-75;
    width: 100%;
    display: grid;
    gap: $spacing-16;
    padding: $spacing-16;
    border-radius: $border-radius;
    border: 1px solid $color-neutral-100;
  }
  &__section-lands {
    display: flex;
    gap: $spacing-16;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.land-box {
  background-color: $color-neutral-50;
  padding: $spacing-8 $spacing-12;
  border: 1px solid $color-neutral-100;
  border-radius: $border-radius;
  transition: all 100ms ease-out;
  &:hover {
    box-shadow: 0 0 0 2px $color-accent-200;
  }
}

.land-checkbox {
  position: relative;
  display: flex;
  gap: $spacing-12;
  align-items: center;

  user-select: none;
  cursor: pointer;
  &--selected {
    .land-checkbox__details {
      color: $color-neutral-900;
    }
  }
  .form__checkbox {
    position: absolute;
    top: $spacing-4;
  }
  .form__label {
    cursor: pointer;
  }
  &__details {
    width: max-content;
    margin-left: $spacing-48 + $spacing-8;
    color: $color-neutral-500;
  }
  &__note {
    font-size: $font-size-14;
    background-color: $color-accent-200;
    color: $color-neutral-900;
    padding: $spacing-2;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;
    margin-top: $spacing-4;
    &--checked {
      background-color: $color-brand-500;
      color: $color-neutral-50;
    }
  }
}

.land-practice {
  display: grid;
  gap: $spacing-16;
  background-color: $color-neutral-50;
  border: 1px solid $color-neutral-200;
  border-radius: $border-radius;
  max-width: 238px;
  &--declared {
    box-shadow: 0 0 0 1px $color-brand-500;
    border-color: $color-brand-500;
    border-radius: 2px;
    .land-practice__header {
      background-color: $color-brand-500;
      color: $color-neutral-50;
    }
    .land-practice__footer {
      background-color: $color-brand-100;
      border-color: $color-brand-500;
    }
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-8;
    font-size: $font-size-20;
    font-weight: bold;
    padding: $spacing-12 $spacing-4;
    border-bottom: 1px solid $color-neutral-200;
  }
  &__body {
    padding: 0 $spacing-16;
  }
  &__footer {
    display: grid;
    justify-items: center;
    align-items: center;
    padding: $spacing-12;
    border-top: 1px solid $color-neutral-200;
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.onboarding {
  align-items: center;
}

.social-profile-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: $border-radius;
}

.land-section-display {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-12;
  &__part {
    display: flex;
    align-items: baseline;
    gap: $spacing-4;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $font-size-12;
  }
  &__part-name {
    color: $color-neutral-500;
  }
  &__part-value {
    border: 1px solid $color-brand-500;
    border-radius: $spacing-4 $spacing-16;
    padding: $spacing-4 $spacing-12;
    color: $color-brand-500;
  }
}

.selected-items {
  padding: $spacing-16;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__details {
    display: grid;
    gap: $spacing-8;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-8;
  }
  &__item {
    background-color: $color-accent-300;
    color: $color-accent-900;
    padding: $spacing-4 $spacing-12;
    border-radius: 2px;
    font-weight: bold;
  }
}

.temp-practices {
  padding: $spacing-8;
  font-size: $font-size-14;
}

.temp-chem-year {
  margin-right: $spacing-4;
  &:not(:last-child) {
    &::after {
      content: ",";
    }
  }
}

.temp-chem-years {
  display: flex;
  flex-wrap: wrap;
}

.temp-congrats {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $spacing-24;
  align-items: flex-start;
  &__image {
    border-radius: $spacing-12;
    height: 100%;
    max-height: 525px;
  }
  &__right {
    display: grid;
    gap: $spacing-20;
    align-items: flex-start;
  }
}

.temp-table {
  display: grid;
  gap: $spacing-12;
  padding: $spacing-12;
  border: 1px solid $color-neutral-200;
  border-radius: $border-radius;
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: $font-size-14;
    font-weight: bold;
    padding: $spacing-4 $spacing-12;
    margin-right: $spacing-24;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &:nth-child(odd) {
      background-color: $color-neutral-75;
    }
    padding: $spacing-4 $spacing-12;
  }
}

.land-temp-1 {
  padding: $spacing-12 $spacing-16;
  border: 1px solid $color-neutral-200;
  border-radius: $border-radius;
  display: grid;
  gap: $spacing-4;
}

@keyframes spinning {
  form {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.full-screen-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  svg {
    animation-name: spinning;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.credits {
  border: 1px solid $color-neutral-100;
  border-radius: $border-radius;
  display: grid;
  gap: $spacing-12;
  padding: $spacing-12 $spacing-16 $spacing-16;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-4;
    flex-wrap: wrap;
  }
  &__title {
    display: flex;
    align-items: center;
    gap: $spacing-4;
    text-transform: capitalize;
    font-size: $font-size-14;
    font-weight: bold;
  }
  &__summery {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid $color-neutral-200;
    border-radius: $border-radius;
    & > div {
      display: grid;
      padding: $spacing-8;
    }
  }
}

.profile-view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(252px, 1fr));
  align-items: start;
  gap: $spacing-16;
  &__image {
    min-height: 320px;
    border-radius: 2px;
    aspect-ratio: 1;
  }
  &__details {
    display: grid;
    gap: $spacing-16;
  }
  &__name {
    font-size: $spacing-48;
    font-weight: bold;
    line-height: 1;
    text-transform: capitalize;
  }
  &__location {
    display: flex;
    align-items: center;
    color: $color-neutral-500;
    font-size: $spacing-16;
    text-transform: capitalize;
    svg {
      transform: translateY(2px);
    }
  }
  &__title {
    font-size: $spacing-16;
    font-weight: bold;
    color: $color-neutral-500;
    text-transform: capitalize;
  }
  &__value {
    font-size: $spacing-16;
  }
}

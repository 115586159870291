.dialog {
  position: fixed;
  min-width: 280px;
  max-width: 420px;
  background: linear-gradient($color-neutral-50, $color-neutral-50);
  color: $color-neutral-900;
  border: none;
  border-radius: $border-radius;
  padding: $spacing-16;
  box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.25);
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-neutral-100;
    padding-bottom: $spacing-16;
    margin-bottom: $spacing-16;
    gap: $spacing-16;
    h1 {
      margin: 0;
    }
  }
  &__content {
    display: grid;
    grid-gap: $spacing-16;
  }
}
.dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(1px);
}

@mixin scroll-snap-x {
  overflow: auto hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  // scroll-behavior: smooth;
  @media (hover: none) {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.tabs {
  background-color: transparentize($color-neutral-50, 0.9);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 220px;
  height: 33vh;
  &__header {
    flex-shrink: 0;
    min-block-size: fit-content;
    display: flex;
    flex-direction: column;
    @include scroll-snap-x();
  }
  &__nav {
    display: flex;
  }
  &__button {
    scroll-snap-align: start;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    background-color: transparentize($color-neutral-50, 0.6);
    border: 1px solid $color-neutral-100;
    border-left: none;
    padding: $spacing-8 $spacing-16;
    color: $color-brand-500;
    font-size: $spacing-16;
    font-weight: bold;
    text-transform: capitalize;
    flex-grow: 1;
    cursor: pointer;
    &:focus {
      outline: 2px solid $color-brand-500;
      outline-offset: -2px;
    }
    &:first-child {
      border-left: 1px solid $color-neutral-100;
    }
    &:last-child {
      border-right: 1px solid $color-neutral-100;
    }
    &--active {
      background-color: transparent;
      border-bottom-color: transparent;
      color: $color-neutral-900;
    }
  }
  &__body {
    display: grid;
    block-size: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    @include scroll-snap-x();
    border: 1px solid transparentize($color-neutral-50, 0.5);
    border-top: none;
  }
  &__tab {
    scroll-snap-align: start;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    padding: $spacing-16;
  }
}

.land-card {
  background-color: $color-neutral-50;
  position: relative;
  display: grid;
  grid-gap: $spacing-8;
  padding: $spacing-12;
  border-radius: $border-radius;
  aspect-ratio: 1;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      transparentize($color-brand-500, 1) 30%,
      transparentize($color-brand-600, 0.8) 60%,
      transparentize($color-brand-700, 0.25) 100%
    );
  }
  &__content {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__initials {
    font-size: $font-size-25;
    font-weight: bold;
    line-height: 0.8;
    background-color: transparentize($color-brand-300, $amount: 0.1);
    color: $color-brand-800;
    border-radius: $border-radius 20px;
    border: 1px solid $color-brand-500;
    padding: $spacing-8 $spacing-12;
    user-select: none;
  }
  &__lld {
    text-align: center;
    font-size: $font-size-20;
    min-width: max-content;
    font-weight: bold;
    color: $color-neutral-50;
  }
  &__title {
    font-size: $font-size-20;
  }
  &--excluded {
    background-color: $color-brand-100;
    &::after {
      display: none;
    }
    .land-item__initials,
    .land-item__lld {
      background-color: $color-brand-200;
      color: $color-brand-500;
      border-color: $color-brand-400;
    }
  }
  &--selectable {
    opacity: 0.2;
    user-select: none;
    cursor: pointer;
    transition: all 100ms ease-out;
    &:hover {
      transform: scale(1.02);
    }
  }
  &--selected {
    opacity: 0.8;
    box-shadow: 0 0 0 $spacing-4 $color-accent-200;
  }
  .map {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 14;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 0;
    filter: saturate(1.25) hue-rotate(16deg);
  }
}

.land-map {
  position: sticky;
  top: 64px;
  min-height: 400px;
  // z-index: 10000;
  &__controls {
    display: flex;
    gap: $spacing-8;
    position: absolute;
    top: $spacing-8;
    right: $spacing-8;
    z-index: 10;
    pointer-events: all;
  }
  &__details {
    display: flex;
    display: none;
    gap: $spacing-16;
    position: absolute;
    top: $spacing-4;
    left: $spacing-4;
    background-color: $color-neutral-50;
    color: $color-brand-500;
    padding: $spacing-2 $spacing-4;
    font-size: $font-size-12;
    border-radius: 2px;
    z-index: 10;
  }
  &__loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-brand-900;
    border-radius: $border-radius;
    opacity: 0.8;
    display: grid;
    justify-content: center;
    align-items: center;
    padding-right: 280px;
    svg {
      path {
        fill: $color-neutral-50;
      }
    }
  }
}

@keyframes body-animation {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2px);
  }
}

@keyframes wheel-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.tractor {
  &__body {
    animation-name: body-animation;
    animation-duration: 100ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  &__back-wheel {
    transform-origin: 74px 135px;
    animation-name: wheel-animation;
    animation-duration: 750ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  &__front-wheel {
    transform-origin: 213.5px 147.5px;
    animation-name: wheel-animation;
    animation-duration: 750ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

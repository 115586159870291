.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  &__wrapper {
    background-color: $color-neutral-50;
    color: $color-neutral-900;
    position: relative;
    min-width: 300px;
    max-height: 90vh;
    overflow: auto;
    border-radius: $border-radius;
    margin: $spacing-8;
  }
  &__header {
    display: flex;
    gap: $spacing-24;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-neutral-100;
    padding: $spacing-12 $spacing-12 $spacing-12 $spacing-20;
  }
  &__body {
    padding: $spacing-20;
    display: grid;
    grid-gap: $spacing-20;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
}

.listing-new {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-16;
  &__summery {
    // background-color: $color-brand-100;
    // padding: $spacing-12;
    border: 1px solid $color-neutral-100;
    border-radius: $border-radius;
    display: grid;
    gap: $spacing-8;
    align-content: stretch;
    transform: translateY(4px);
    & > div {
      display: grid;
      gap: $spacing-2;
      align-items: center;
      align-content: center;
      border-bottom: 1px solid $color-neutral-100;
      padding: $spacing-4 $spacing-12;
      &:last-child {
        overflow-x: auto;
        border-bottom: none;
      }
    }
  }
}

.menu {
  display: grid;
  gap: $spacing-8;
  margin-right: $spacing-24;
  position: sticky;
  top: 97px;
  @media screen and (max-width: $break-point-medium) {
    position: fixed;
    top: $spacing-48 + $spacing-24 + $spacing-16;
    left: 0;
    bottom: $spacing-16;
    background-color: $color-neutral-50;
    align-items: flex-start;
    align-content: flex-start;
    padding: $spacing-16;
    border: 1px solid $color-neutral-100;
    border-radius: 0 $border-radius $border-radius 0;
    z-index: 100;
    transform: translateX(0px);
    transition: transform 100ms ease-out;
    box-shadow: $drop-shadow;
  }
  &--closed {
    @media screen and (max-width: $break-point-medium) {
      transform: translateX(-500px);
    }
  }
  &__link {
    position: relative;
    text-decoration: none;
    font-weight: $font-weight-medium;
    min-width: max-content;
    height: $spacing-48;
    display: flex;
    gap: $spacing-8;
    align-items: center;
    text-transform: capitalize;
    color: $color-neutral-500;
    padding: 0 $spacing-12;
    border-radius: $border-radius;
    transition: all 100ms ease-out;
    &:hover {
      background: $color-brand-100;
      color: $color-brand-500;
    }
    &:active {
      background: $color-brand-500;
      color: $color-neutral-50;
    }
    &--active {
      background: $color-brand-500;
      color: $color-neutral-50;
      &:hover {
        background: $color-brand-400;
        color: $color-neutral-50;
      }
    }
    &--coming-soon {
      color: $color-neutral-300;
      &::after {
        content: "coming soon";
        font-size: 10px;
        color: $color-neutral-50;
        background-color: $color-neutral-300;
        padding: $spacing-2 $spacing-4;
        border-radius: 2px;
        position: absolute;
        font-weight: normal;
        right: 0;
        top: -1px;
        transition: all 100ms ease-out;
      }
      &:hover {
        &::after {
          background-color: $color-brand-500;
        }
        color: $color-brand-500;
      }
      &:active {
        background: $color-brand-100;
        color: $color-neutral-300;
      }
    }
  }
}

.menu-toggle {
  display: none;
  @media screen and (max-width: $break-point-medium) {
    display: initial;
  }
}

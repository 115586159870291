.land-item {
  display: grid;
  gap: $spacing-8;
  background-color: $color-neutral-50;
  text-align: center;
  padding: $spacing-8;
  border: 1px solid $color-neutral-200;
  border-radius: $border-radius;
  width: 240px;
  &--map-editor {
    width: auto;
    grid-template-columns: 78px auto;
    .land-item__body {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
    }
    .land-item__description {
      font-size: $font-size-16;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    gap: $spacing-8;
    align-items: center;
    padding: 0 $spacing-2 0 $spacing-4;
  }
  &__image {
    border-radius: $border-radius;
    background-color: $color-neutral-100;
    max-width: 100%;
  }
  &__controls {
    display: flex;
    gap: $spacing-8;
  }
  &__description {
    font-weight: bold;
    min-width: max-content;
    font-size: $font-size-16;
  }
}

.popup-tip {
  display: inline-block;
  position: relative;
  text-transform: none;
  font-weight: normal;
  margin-left: $spacing-2;
  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: $color-accent-700;
    font-size: $font-size-16;
    padding: $spacing-2;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    svg {
      transform: translateY(1.5px);
    }
  }
  &__content {
    position: absolute;
    z-index: 100;
    width: 300px;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-75%);
    background-color: $color-neutral-50;
    padding: $spacing-12;
    border: 1px solid $color-accent-700;
    box-shadow: rgba(0, 0, 0, 0.05) 1px 6px 8px 4px;
    display: grid;
    gap: $spacing-4;
    font-size: $font-size-14;
    border-radius: $border-radius;
    &::before {
      content: "";
      background-color: $color-neutral-50;
      height: 16px;
      width: 16px;
      top: -9px;
      left: 75%;
      transform: translateX(-50%) rotateZ(45deg);
      position: absolute;
      border-left: 1px solid $color-accent-700;
      border-top: 1px solid $color-accent-700;
      border-radius: 4px 0 0 0;
    }
  }
}

body {
  color: $color-neutral-900;
  font-family: "Inter", sans-serif;
  font-size: $font-size-16;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter Tight", sans-serif;
  margin: 0;
  margin-top: -4px;
  text-transform: capitalize;
  line-height: 1;
}

p {
  margin: 0;
  line-height: 1.3;
  svg {
    transform: translateY(2px);
  }
  &::first-letter {
    text-transform: capitalize;
  }
}

h1 {
  font-size: $font-size-28;
}
h2 {
  font-size: $font-size-22;
}
h3 {
  font-size: $font-size-18;
}
h4 {
  font-size: $font-size-16;
}
h5 {
  font-size: $font-size-14;
}
h6 {
  font-size: $font-size-12;
}

.subtitle {
  font-size: $font-size-14;
  color: $color-neutral-500;
  &::first-letter {
    text-transform: uppercase;
  }
}

.title {
  text-transform: capitalize;
  font-weight: bold;
}
.value {
  color: $color-brand-600;
  font-size: $spacing-16;
  font-weight: bold;
  &--large {
    font-size: $font-size-36;
  }
}

.caption {
  font-size: $font-size-12;
}

.highlight {
  background-color: $color-accent-100;
  color: $color-accent-600;
  padding: $spacing-2 $spacing-8;
  font-weight: bold;
  border-radius: 1px;
}

.help-text {
  color: $color-neutral-600;
  font-size: $font-size-14;
  &::first-letter {
    text-transform: capitalize;
  }
}

.land-list {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-8;
  align-items: flex-start;
  border-radius: $border-radius;
  overflow: auto;
  pointer-events: all;
  min-width: 290px;
  max-height: 100%;
  &--map-editor {
    display: grid;
    background-color: $color-neutral-50;
    padding: $spacing-8;
    border: 1px solid $color-neutral-200;
  }
}

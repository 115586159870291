.empty {
  background-color: $color-neutral-75;
  color: $color-neutral-600;
  padding: $spacing-8 $spacing-16;
  border-radius: $border-radius;
  font-size: $font-size-14;
}

.pair {
  display: flex;
  &__title {
    background-color: $color-neutral-50;
    padding: $spacing-12 $spacing-16;
    border-right: $color-neutral-100;
    border-radius: $border-radius 0 0 $border-radius;
    text-transform: capitalize;
  }
  &__value {
    background-color: transparentize($color-neutral-50, 0.5);
    padding: $spacing-12 $spacing-16;
    border-radius: 0 $border-radius $border-radius 0;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.remove {
  position: absolute;
  transform: translateY(-200%);
  background-color: $color-danger-500;
  opacity: 0;
  transition: all 500ms ease-out;
}

.warning {
  background-color: rgb(221, 208, 132);
  color: $color-neutral-50;
  font-weight: bold;
  border-radius: $border-radius;
  text-transform: capitalize;
  padding: $spacing-8 $spacing-16;
  display: flex;
  gap: $spacing-8;
}

.flex-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-32;
  row-gap: $spacing-16;
  align-items: flex-end;
}

.panel {
  width: 100%;
  background-color: $color-neutral-75;
  padding: $spacing-16;
  border-radius: $border-radius;
  display: grid;
  gap: $spacing-16;
  justify-items: center;
  align-items: start;
  align-content: start;
  border: 1px solid $color-neutral-100;
  &--highlight {
    background-color: $color-brand-100;
    border-color: $color-brand-200;
  }
}

.tag {
  width: 100%;
  text-align: center;
  justify-self: start;
  background-color: $color-neutral-100;
  padding: $spacing-8 $spacing-16;
  font-weight: bold;
  color: $color-neutral-900;
  text-transform: capitalize;
  border-radius: 2px 20px;
  &--highlight {
    background-color: $color-brand-200;
    color: $color-neutral-900;
  }
}

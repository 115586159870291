.enrollment-intro {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $spacing-16;
  &__image {
    border-radius: $spacing-12;
    height: 100%;
    max-height: 400px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: $spacing-8;
    gap: $spacing-16;
  }
  &__content {
    display: grid;
    gap: $spacing-16;
    align-items: flex-start;
    font-size: $font-size-14;
  }
}

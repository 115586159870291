.status {
  background-color: $color-brand-100;
  font-size: $font-size-14;
  color: $color-brand-600;
  text-transform: capitalize;
  font-weight: bold;
  padding: $spacing-4 $spacing-12;
  border-radius: 100px;
  display: flex;
  gap: $spacing-4;
  justify-content: center;
  align-items: center;
  &--incomplete {
    background-color: $color-warning-100;
    color: $color-warning-700;
  }
  &--pending {
    background-color: $color-accent-100;
    color: $color-accent-700;
  }
  &--action-required {
    background-color: $color-warning-100;
    color: $color-warning-700;
  }
}

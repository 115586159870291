.enrollment-item {
  background-color: $color-neutral-75;
  padding: $spacing-16;
  display: grid;
  gap: $spacing-16;
  &__status {
    width: fit-content;
    background-color: $color-neutral-100;
    padding: $spacing-8 $spacing-16;
    font-weight: bold;
    color: $color-neutral-900;
    text-transform: capitalize;
    border-radius: $border-radius;
  }
}

:focus {
  outline-color: $color-brand-500;
}

.form {
  position: relative;
  display: grid;
  grid-gap: $spacing-16;
  &__group {
    display: grid;
    grid-gap: $spacing-8;
    align-content: start;
  }
  &__label {
    text-transform: capitalize;
    font-size: $font-size-16;
    font-weight: bold;
    &::after {
      content: "";
      color: $color-brand-500;
      font-size: $spacing-12;
    }
    &--no-tag {
      &::after {
        content: "";
      }
    }
  }
  &__input {
    width: 100%;
    min-width: 80px;
    background-color: $color-neutral-50;
    border: 1px solid $color-neutral-200;
    padding: $spacing-12;
    color: $color-neutral-900;
    border-radius: $border-radius;
    font-size: $font-size-18;
    transition: all 100ms ease-out;
    &::placeholder {
      font-size: $font-size-14;
      color: $color-neutral-500;
    }
  }
  &__input[type="file"] {
    border: none;
  }
  &__checkbox {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 48px;
    height: 48px;
    background-color: $color-neutral-75;
    border: 1px solid $color-neutral-300;
    border-radius: 0.15em;
    cursor: pointer;
    transition: background-color 100ms ease-out;
    &::before {
      content: "";
      width: 36px;
      height: 36px;
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 2px;
      transition: all 100ms ease-out;
    }
    &::after {
      content: "";
      width: 100%;
      height: 40%;
      border-left: 8px solid darken($color-neutral-50, 5%);
      border-bottom: 8px solid darken($color-neutral-50, 5%);
      border-radius: 0 0 0 12px;
      display: block;
      transform-origin: center;
      transform: translate(-3px, 8px) scale(0.45) rotateZ(-45deg);
      transition: transform 100ms ease-out;
    }
    &:checked:before {
      background-color: $color-brand-500;
    }
  }
  &__url-input {
    display: grid;
    grid-template-columns: auto 1fr;
    .form__input {
      border-radius: 0 $border-radius $border-radius 0;
    }
    .errors {
      grid-column: span 2;
      margin-top: 0;
    }
  }
  &__url-input-protocol {
    height: 100%;
    background-color: $color-neutral-50;
    font-weight: bold;
    font-size: $font-size-14;
    color: $color-neutral-400;
    padding: 0 $spacing-12;
    display: grid;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-neutral-200;
    border-right: none;
    border-radius: $border-radius 0 0 $border-radius;
    user-select: none;
  }
}

.file-upload {
  background-color: $color-neutral-75;
  flex-grow: 1;
  display: grid;
  border: 1px solid $color-neutral-200;
  border-radius: $border-radius;
  &__section {
    padding: $spacing-12;
    &:not(:last-child) {
      border-bottom: 1px solid $color-neutral-200;
    }
  }
  &__files {
    display: grid;
    gap: $spacing-8;
    font-size: $font-size-14;
    font-weight: bold;
    line-break: anywhere;
  }
  &__file {
    border: 1px solid $color-neutral-200;
    background-color: $color-neutral-75;
    border-radius: 2px;
    padding: $spacing-8;
    color: $color-accent-600;
    display: flex;
    gap: $spacing-4;
    align-items: center;
  }
  &__file-size {
    font-weight: normal;
    font-size: $font-size-12;
    color: $color-neutral-600;
  }
  &__file-remove {
    margin-left: auto;
    color: $color-danger-500;
    background-color: transparent;
    border: 1px solid $color-danger-500;
    border: none;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.drop-zone {
  display: grid;
  gap: $spacing-12;
  border-radius: $border-radius $border-radius 0 0;
  transition: all 100ms ease-out;
  &--active {
    background-color: $color-accent-100;
    .drop-zone__zone {
      color: $color-accent-500;
      border-color: $color-accent-500;
    }
  }
  &__zone {
    width: 100%;
    height: 176px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: $spacing-12;
    padding: $spacing-8 $spacing-12;
    border: 2px dashed $color-neutral-300;
    border-radius: $border-radius;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__label {
    font-weight: bold;
    color: $color-neutral-400;
    user-select: none;
    pointer-events: none;
  }
}

.file-upload-progress {
  height: $spacing-8;
  border-radius: $spacing-4;
  background-color: $color-neutral-50;
  overflow: hidden;
  &__indicator {
    background-color: $color-brand-500;
    width: 0%;
    height: 100%;
    display: flex;
  }
}

.errors {
  list-style: none;
  grid-gap: $spacing-12;
  padding: 0 $spacing-8;
  margin: 0;
  margin-top: -$spacing-8;
  &__issue {
    // background-color: $color-danger-500;
    font-size: $font-size-14;
    font-weight: bold;
    color: $color-danger-500;
    border: 1px solid $color-danger-500;
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
    margin: 0;
    padding: $spacing-8 $spacing-12;
    &::first-letter {
      text-transform: capitalize;
    }
    span {
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

.required {
  &::after {
    content: " *";
    color: $color-brand-500;
    font-weight: bold;
  }
}

.invalid {
  border: 1px solid $color-danger-500;
  background-color: $color-danger-100;
}

.multiselect {
  position: relative;
  width: fit-content;
  &__button {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-8;
    width: auto;
    min-width: auto;
    padding: $spacing-8 $spacing-12;
    &--open {
      border-radius: $border-radius $border-radius $border-radius 0;
    }
  }
  &__selected-values {
    display: flex;
    gap: $spacing-4;
  }
  &__selected-value {
    background-color: $color-brand-500;
    color: $color-neutral-50;
    padding: $spacing-2 $spacing-12;
    border-radius: 2px;
    font-size: $font-size-16;
    font-weight: normal;
  }
  &__window {
    display: grid;
    gap: $spacing-12;
    position: absolute;
    top: calc(100% - 1px);
    background-color: $color-neutral-50;
    width: 256px;
    height: 280px;
    overflow-y: scroll;
    z-index: 100;
    padding: $spacing-12;
    border: 1px solid $color-brand-500;
    border-top: 1px solid $color-neutral-100;
    border-radius: 0 0 0 $border-radius;
  }
  &__option {
    display: grid;
    grid-template-columns: 48px auto;
    gap: $spacing-4;
  }
  &__option-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: $spacing-8;
    border-radius: 2px;
    &:hover {
      background-color: $color-brand-200;
      color: $color-neutral-900;
    }
    &--selected {
      background-color: $color-brand-500;
      color: $color-neutral-50;
      &:hover {
        background-color: $color-brand-700;
        color: $color-neutral-50;
      }
    }
  }
}

.image-field {
  display: flex;
  gap: $spacing-8;
  flex-wrap: wrap;
  align-items: flex-start;
  &__preview {
    display: grid;
    gap: $spacing-8;
  }
  &__image {
    width: 144px;
    height: 144px;
    border: 1px solid $color-neutral-200;
    border-radius: $border-radius;
  }
  &__image-remove {
    width: 100%;
    justify-content: center;
  }
}

.document-item {
  background-color: $color-neutral-75;
  padding: $spacing-8 $spacing-16;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__link {
    font-size: $spacing-16;
  }
}

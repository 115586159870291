.toggle {
  width: max-content;
  min-width: $spacing-48;
  height: $spacing-48;
  display: flex;
  gap: $spacing-4;
  padding: $spacing-4;
  border-radius: $border-radius;
  background-color: $color-neutral-50;
  border: 1px solid $color-brand-500;
  border-bottom: 2px solid $color-brand-500;
  transition: all 100ms ease-out;
  &__button {
    background-color: $color-neutral-50;
    display: flex;
    gap: $spacing-8;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    color: $color-neutral-500;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 3px;
    width: 109px;
    transition: transform 100ms ease-out;
    cursor: pointer;
    &:hover {
      background-color: $color-brand-100;
    }
    &--active {
      background-color: $color-brand-500;
      color: $color-neutral-50;
      &:hover {
        background-color: $color-brand-400;
      }
    }
  }
}

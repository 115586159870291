.business-item {
  display: flex;
  justify-content: space-between;
  gap: $spacing-16;
  align-items: center;
  padding: $spacing-16 $spacing-8;
  border-bottom: 1px solid $color-neutral-100;
  &__name {
    font-size: $spacing-16;
    font-weight: bold;
    text-transform: capitalize;
  }
}

// flex
.flex {
  display: flex;
  gap: $spacing-12;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-basis-50 {
  flex-basis: 49%;
}

// grid
.grid {
  display: grid;
}

// flex/grid

.gap-4 {
  gap: $spacing-4;
}

.gap-8 {
  gap: $spacing-8;
}

.gap-12 {
  gap: $spacing-12;
}

.gap-16 {
  gap: $spacing-16;
}

.gap-24 {
  gap: $spacing-24;
}

.space-between {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.grid-full-span {
  grid-column: 1 / -1;
}

.toRight {
  margin-left: auto;
}

// color

.color-neutral-50 {
  color: $color-neutral-50;
}
.color-neutral-75 {
  color: $color-neutral-75;
}
.color-neutral-100 {
  color: $color-neutral-100;
}
.color-neutral-200 {
  color: $color-neutral-200;
}
.color-neutral-300 {
  color: $color-neutral-300;
}
.color-neutral-400 {
  color: $color-neutral-400;
}
.color-neutral-500 {
  color: $color-neutral-500;
}
.color-neutral-600 {
  color: $color-neutral-600;
}
.color-neutral-700 {
  color: $color-neutral-700;
}
.color-neutral-800 {
  color: $color-neutral-800;
}
.color-neutral-900 {
  color: $color-neutral-900;
}

.color-brand-100 {
  color: $color-brand-100;
}
.color-brand-200 {
  color: $color-brand-200;
}
.color-brand-300 {
  color: $color-brand-300;
}
.color-brand-400 {
  color: $color-brand-400;
}
.color-brand-500 {
  color: $color-brand-500;
}
.color-brand-600 {
  color: $color-brand-600;
}
.color-brand-700 {
  color: $color-brand-700;
}
.color-brand-800 {
  color: $color-brand-800;
}
.color-brand-900 {
  color: $color-brand-900;
}

// background color

.background-color-brand-100 {
  background-color: $color-brand-100;
}
.background-color-brand-200 {
  background-color: $color-brand-200;
}
.background-color-brand-300 {
  background-color: $color-brand-300;
}
.background-color-brand-400 {
  background-color: $color-brand-400;
}
.background-color-brand-500 {
  background-color: $color-brand-500;
}
.background-color-brand-600 {
  background-color: $color-brand-600;
}
.background-color-brand-700 {
  background-color: $color-brand-700;
}
.background-color-brand-800 {
  background-color: $color-brand-800;
}
.background-color-brand-900 {
  background-color: $color-brand-900;
}

// font sizes

.font-size-12 {
  font-size: $font-size-12;
}
.font-size-14 {
  font-size: $font-size-14;
}
.font-size-16 {
  font-size: $font-size-16;
}
.font-size-18 {
  font-size: $font-size-18;
}
.font-size-20 {
  font-size: $font-size-20;
}
.font-size-22 {
  font-size: $font-size-22;
}
.font-size-25 {
  font-size: $font-size-25;
}
.font-size-28 {
  font-size: $font-size-28;
}
.font-size-32 {
  font-size: $font-size-32;
}
.font-size-36 {
  font-size: $font-size-36;
}
.font-size-40 {
  font-size: $font-size-40;
}
.font-size-45 {
  font-size: $font-size-45;
}
.font-size-50 {
  font-size: $font-size-50;
}
.font-size-60 {
  font-size: $font-size-60;
}

// alignment

.text-center {
  text-align: center;
}

// other

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.cap {
  text-transform: capitalize;
}

.upper {
  text-transform: uppercase;
}

.limited-width {
  width: 65%;
  justify-self: center;
}

.list {
  display: grid;
  align-items: flex-start;
  gap: $spacing-24;
  &--small-gap {
    gap: $spacing-8;
  }
  &--gap-4 {
    gap: $spacing-4;
  }
  &--gap-8 {
    gap: $spacing-8;
  }
  &--gap-12 {
    gap: $spacing-12;
  }
  &--gap-16 {
    gap: $spacing-16;
  }
}

.ram {
  display: grid;
  gap: $spacing-16;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.guest-page {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-16;
}

// padding

.padding-2 {
  padding: $spacing-2;
}
.padding-4 {
  padding: $spacing-4;
}
.padding-8 {
  padding: $spacing-8;
}
.padding-12 {
  padding: $spacing-12;
}
.padding-16 {
  padding: $spacing-16;
}
.padding-24 {
  padding: $spacing-24;
}
.padding-32 {
  padding: $spacing-32;
}
.padding-40 {
  padding: $spacing-40;
}
.padding-48 {
  padding: $spacing-48;
}
.padding-64 {
  padding: $spacing-64;
}
.padding-80 {
  padding: $spacing-80;
}
.padding-96 {
  padding: $spacing-96;
}

.margin-2 {
  margin: $spacing-2;
}
.margin-4 {
  margin: $spacing-4;
}
.margin-8 {
  margin: $spacing-8;
}
.margin-12 {
  margin: $spacing-12;
}
.margin-16 {
  margin: $spacing-16;
}
.margin-24 {
  margin: $spacing-24;
}
.margin-32 {
  margin: $spacing-32;
}
.margin-40 {
  margin: $spacing-40;
}
.margin-48 {
  margin: $spacing-48;
}
.margin-64 {
  margin: $spacing-64;
}
.margin-80 {
  margin: $spacing-80;
}
.margin-96 {
  margin: $spacing-96;
}

// borders

.border-radius {
  border-radius: $border-radius;
}

// width

.width-100pc {
  width: 100%;
}

.no-margin {
  margin: 0;
}

.fullscreen {
  width: 100%;
  height: 100%;
}

.relative {
  position: relative;
}

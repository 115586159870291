.card {
  display: grid;
  border-radius: $border-radius;
  background-color: $color-neutral-50;
  border: 1px solid $color-neutral-100;
  overflow: hidden;
  transition: all 100ms ease-out;
  &:hover {
    border-color: $color-brand-200;
    box-shadow: 0 0 0 1px $color-brand-500;
    .card__image {
      transform: scale(1.1) translateY(-9.2px);
    }
  }
  &__image {
    height: 200px;
    border-radius: $border-radius $border-radius 0 0;
    transition: all 100ms ease-out;
  }
  &__body {
    display: grid;
    gap: $spacing-12;
    padding: $spacing-16;
    font-size: $font-size-14;
    min-height: 220px;
  }
}

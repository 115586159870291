.credits-portfolio {
  display: grid;
  gap: $spacing-24;
  &__total {
    display: grid;
  }
  &__title {
    font-weight: bold;
    text-transform: capitalize;
  }
  &__value {
    color: $color-brand-500;
    font-weight: bold;
    font-size: $spacing-24;
  }
  &__ratio {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: $spacing-16;
  }
  &__ratio-bar {
    display: flex;
  }
  &__ratio-bar-standard {
    background-color: $color-brand-100;
    font-size: $font-size-14;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid $color-neutral-900;
    border-right: 0;
    border-radius: $border-radius 0 0 $border-radius;
    height: 100%;
    flex-basis: 50%;
    min-width: 48px;
  }
  &__ratio-bar-premium {
    background-color: $color-brand-500;
    color: $color-neutral-50;
    font-size: $font-size-14;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid $color-neutral-900;
    border-left: 0;
    border-radius: 0 $border-radius $border-radius 0;
    height: 100%;
    flex-basis: 50%;
    min-width: 48px;
  }
}

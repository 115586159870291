.failed-getting-user {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-items: center;
}

.land-editor {
  position: relative;
  display: grid;
  gap: $spacing-8;
  height: 75vh;
  &__ui {
    position: absolute;
    top: $spacing-8;
    right: $spacing-8;
    bottom: $spacing-8;
    left: $spacing-8;
    display: grid;
    gap: $spacing-8;
    justify-items: right;
    align-content: flex-start;
    align-items: flex-start;
    pointer-events: none;
  }
  &__controls {
    display: flex;
    gap: $spacing-8;
    pointer-events: all;
  }
  &__owned-lands-search {
    width: 290px;
    background-color: $color-neutral-50;
    padding: $spacing-8;
    padding-left: $spacing-12;
    border: 1px solid $color-brand-500;
    border-radius: $border-radius;
    pointer-events: all;
    display: flex;
    gap: $spacing-12;
    color: $color-brand-500;
  }
  &__map-style-toggle {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.mapboxgl-ctrl-logo {
  transform: translateY(-58px) translateX(3px) !important;
}

.mapboxgl-ctrl-geocoder--input {
  min-width: 80px;
  background-color: $color-neutral-50 !important;
  border: 1px solid $color-brand-500 !important;
  padding-top: $spacing-12 !important;
  padding-bottom: $spacing-12 !important;
  height: auto !important;
  color: $color-neutral-900;
  border-radius: $border-radius;
  font-size: $font-size-18;
  transition: all 100ms ease-out;
  &::placeholder {
    font-size: $font-size-14;
    color: $color-neutral-500;
  }
}

.mapboxgl-ctrl-geocoder--icon {
  transform: translateY(5px);
}

.ordered-list {
  list-style: none;
  display: grid;
  gap: $spacing-8;
  padding-left: $spacing-24;
  margin: 0;
  &__item {
    display: grid;
    grid-template-columns: $spacing-48 auto;
    gap: $spacing-8;
    align-items: center;
  }
  &__number {
    background-color: $color-brand-100;
    width: $spacing-32;
    height: $spacing-32;
    display: grid;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $color-brand-500;
    border-radius: $border-radius;
    line-height: 1;
  }
}

.weather {
  padding: 0 $spacing-12;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $spacing-16;
  &__main {
    display: grid;
    grid-gap: $spacing-4;
  }
  &__condition {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $spacing-4;
    align-items: center;
    margin-left: -$spacing-12;
  }
  &__condition-icon {
    width: 48px;
  }
  &__condition-text {
    font-size: 2rem;
    font-weight: $font-weight-bold;
  }
  &__temp-text {
    font-size: 4rem;
    font-weight: $font-weight-bold;
    display: flex;
    line-height: 1;
    &--c::after {
      content: "°C";
      font-size: 2rem;
      color: $color-accent-200;
    }
  }
  &__aside {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &__group {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    grid-gap: $spacing-4;
  }
  &__title {
    font-size: 1.25rem;
    &::first-letter {
      text-transform: capitalize;
    }
    color: $color-accent-200;
  }
  &__value {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
  }
}
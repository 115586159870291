.avatar {
  position: relative;
  width: $spacing-48;
  height: $spacing-48;
  &__image {
    border-radius: 50%;
    box-shadow: 0 0 0 1px $color-brand-500;
    cursor: pointer;
    transition: box-shadow 100ms ease-out;
    &:hover {
      box-shadow: 0 0 0 2px $color-brand-500;
    }
  }
  &__menu {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: max-content;
    min-width: 180px;
    display: grid;
    gap: $spacing-8;
    background-color: $color-neutral-50;
    border-radius: $border-radius;
    border: 1px solid $color-neutral-100;
    padding: $spacing-16 0 $spacing-8;
    box-shadow: $drop-shadow;
    z-index: 100;
  }
  &__name {
    text-transform: capitalize;
    padding: 0 $spacing-16 $spacing-12;
    font-weight: bold;
  }
  &__group {
    border-bottom: 1px solid $color-neutral-100;
  }
  &__link {
    display: flex;
    gap: $spacing-8;
    align-items: center;
    text-transform: capitalize;
    text-decoration: none;
    color: $color-brand-500;
    padding: $spacing-12 $spacing-16;
    cursor: pointer;
    transition: all 100ms ease-out;
    &:hover {
      background-color: $color-brand-500;
      color: $color-neutral-50;
    }
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: $color-neutral-75;
}

body {
  overflow-y: scroll;
}

img {
  width: 100%;
  object-fit: cover;
}

svg {
  height: auto;
  width: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  min-height: 100%;
}

.display {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: stretch;
  padding: $spacing-24 $spacing-20;
  position: relative;
}

.main {
  width: 100%;
  &--blur {
    @media screen and (max-width: $break-point-medium) {
      opacity: 0.5;
      pointer-events: none;
      filter: blur(2px);
    }
  }
}

.widget-grid {
  display: grid;
  gap: $spacing-20;
  align-items: start;
  @media screen and (min-width: $break-point-small) {
    grid-template-columns: 2fr 1fr;
  }
  &__main,
  &__aside {
    display: grid;
    gap: $spacing-20;
  }
  &__aside {
    grid-row: 1;
    @media screen and (min-width: $break-point-small) {
      grid-column: 2;
    }
  }
  &--50-50 {
    @media screen and (min-width: $break-point-small) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.limit-scroll {
  max-height: 200px;
  overflow-y: scroll;
  display: grid;
  gap: $spacing-8;
}

.account-setup {
  max-width: 800px;
  margin: 1vw auto 0;
}

.row {
  border: 1px solid $color-neutral-100;
  padding: $spacing-12 $spacing-24;
  border-radius: $border-radius;
  display: flex;
  gap: $spacing-16;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

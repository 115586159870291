.resource-list {
  display: grid;
  gap: $spacing-16;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 368px));
    gap: $spacing-16;
    justify-content: center;
  }
}

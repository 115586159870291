.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: transparentize($color-neutral-50, 0.1);
  backdrop-filter: blur(24px);
  display: flex;
  gap: $spacing-20;
  flex-wrap: wrap;
  row-gap: $spacing-16;
  align-items: center;
  padding: $spacing-12 $main-side-padding;
  border-bottom: 1px solid $color-neutral-100;
  z-index: 10000;
  &__logo {
    width: auto;
    // @media screen and (min-width: $break-point-small) {
    //   width: $sidebar-width;
    // }
  }
  &__title {
    order: 1;
    width: 100%;
    @media screen and (min-width: $break-point-medium) {
      margin-left: 100px;
    }
    @media screen and (min-width: 600px) {
      order: 0;
      width: auto;
    }
  }
  &__controls {
    margin-left: auto;
    display: flex;
    gap: $spacing-16;
  }
}

// neutral colors
$color-neutral-50: hsl(210, 10%, 100%);
$color-neutral-75: hsl(210, 10%, 98%);
$color-neutral-100: hsl(210, 10%, 92%);
$color-neutral-200: hsl(210, 9%, 86%);
$color-neutral-300: hsl(210, 9%, 68%);
$color-neutral-400: hsl(210, 8%, 58%);
$color-neutral-500: hsl(210, 8%, 48%);
$color-neutral-600: hsl(210, 7%, 38%);
$color-neutral-700: hsl(210, 7%, 28%);
$color-neutral-800: hsl(210, 6%, 18%);
$color-neutral-900: hsl(210, 6%, 08%);

// brand colors, success
$color-brand-100: hsl(150, 45%, 94%);
$color-brand-200: hsl(150, 50%, 77%);
$color-brand-300: hsl(150, 55%, 62%);
$color-brand-400: hsl(150, 60%, 40%);
$color-brand-500: hsl(150, 65%, 35%); // primary
$color-brand-600: hsl(150, 65%, 28%);
$color-brand-700: hsl(150, 65%, 22%);
$color-brand-800: hsl(149, 65%, 17%);
$color-brand-900: hsl(151, 65%, 12%);

// accent color, informative
$color-accent-100: hsl(195, 95%, 92%);
$color-accent-200: hsl(195, 90%, 85%);
$color-accent-300: hsl(195, 80%, 75%);
$color-accent-400: #67c5e4;
$color-accent-500: hsl(195, 60%, 55%);
$color-accent-600: hsl(195, 50%, 45%);
$color-accent-700: hsl(195, 40%, 35%);
$color-accent-800: hsl(195, 30%, 25%);
$color-accent-900: hsl(195, 20%, 20%);

// warning
$color-warning-100: hsl(60, 90%, 82%);
$color-warning-200: hsl(59, 85%, 78%);
$color-warning-300: hsl(58, 80%, 68%);
$color-warning-400: hsl(57, 75%, 58%);
$color-warning-500: hsl(56, 70%, 48%);
$color-warning-600: hsl(55, 65%, 38%);
$color-warning-700: hsl(54, 60%, 28%);
$color-warning-800: hsl(53, 55%, 18%);
$color-warning-900: hsl(52, 50%, 08%);

// danger
$color-danger-100: hsl(8, 60%, 95%);
$color-danger-200: hsl(8, 60%, 80%);
$color-danger-300: hsl(8, 60%, 65%);
$color-danger-400: hsl(8, 60%, 55%);
$color-danger-500: hsl(8, 60%, 45%);
$color-danger-600: hsl(8, 60%, 35%);
$color-danger-700: hsl(8, 60%, 25%);
$color-danger-800: hsl(8, 60%, 15%);
$color-danger-900: hsl(8, 60%, 08%);

$border-radius: 4px;

$spacing-2: 0.125rem;
$spacing-4: 0.25rem;
$spacing-8: 0.5rem;
$spacing-12: 0.75rem;
$spacing-16: 1rem;
$spacing-20: 1.25rem;
$spacing-24: 1.5rem;
$spacing-32: 1.875rem;
$spacing-40: 2.25rem;
$spacing-48: 3rem;
$spacing-64: 3.75rem;
$spacing-80: 4.5rem;
$spacing-96: 4.5rem;

$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-25: 1.563rem;
$font-size-28: 1.75rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-40: 2.5rem;
$font-size-45: 2.813rem;
$font-size-50: 3.125rem;
$font-size-60: 3.75rem;

$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 800;

$drop-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;

$break-point-tiny: 768px;
$break-point-small: 1224px;
$break-point-medium: 1400px;

$sidebar-width: 200px;
$main-side-padding: $spacing-24;
